import React, { useContext, useEffect } from 'react';
import { SitecoreContext, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import './index.scss';
import { ICustomContext } from '../../model/ICustomContext';

type LangugageSelectorEditorProps = {
  sitecoreContext: SitecoreContext & {
    pageEditing: boolean;
    custom: ICustomContext;
    language: string;
    itemId: string;
  };
};

const LangugageSelector = ({ sitecoreContext }: LangugageSelectorEditorProps): JSX.Element => {
  // TODO dynamically pull in values
  let languages: Record<string, string> = { en: 'English', it: 'Italiano' };
  var langCode = sitecoreContext?.language.toLowerCase();
  const [language, setLanguage] = React.useState(languages[langCode]);

  const context = React.createContext(sitecoreContext);
  const currentContext = useContext(context);

  useEffect(() => {
    var langCode = sitecoreContext?.language.toLowerCase();
    setLanguage(languages[langCode]);
  }, [currentContext]);

  const handleChange = (event: any) => {
    setLanguage(event.currentTarget.dataset.value);
    if (typeof window !== 'undefined') {
      window.location.reload();
    }
  };

  function getMenuLinks() {
    let links = [];
    for (var key in languages) {
      if (key !== null) {
        let link = (
          <MenuItem value={languages[key]}>
            <a key={key} href={currentContext?.custom?.urlPathTranslations[key]}>
              {languages[key]}
            </a>
          </MenuItem>
        );

        links.push(link);
      }
    }
    return links;
  }

  return (
    <div className="language-selector">
      <img alt="globe-icon" src="/-/media/Project/UPMC/Italy/Icons/iconglobe.svg" />
      <Select
        id="languageSelect"
        aria-label="language selector"
        displayEmpty
        value={language}
        onChange={handleChange}
      >
        {getMenuLinks().map((link) => link)}
      </Select>
    </div>
  );
};

export default withSitecoreContext()(LangugageSelector);
